.gallery-image {
  width: 33.333vw;
  height: 25vw;
  object-fit: cover;
  object-position: 0 10%;

  transition: transform 0.2s;
}

.gallery-image:hover {
  transform: scale(1.1);
}